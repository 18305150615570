
/**
 * Client.
 */
const sanityClient = require( '@sanity/client' );
const axios = require( 'axios' );

export const client = sanityClient( {
  projectId: process.env.SANITY_PROJECT,
  dataset: 'production',
  useCdn: true,
} );
export const graphqlClient = axios.create( {
  baseURL: `https:${process.env.SANITY_PROJECT}.apicdn.sanity.io/v1/graphql/production`,
} );